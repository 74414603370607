import React, { useCallback, useRef } from 'react'
import { Footer, Header, Layout, Nav } from '../components'
import { Home } from '../containers'

const IndexPage = () => {
	const navRef = useRef<NavRef>(null)

	const onBurger = useCallback(() => {
		if (navRef.current) {
			navRef.current.show()
		}
	}, [navRef.current])

	return (
		<Layout intro>
			<Home />
		</Layout>
	)
}

export default IndexPage
